.woocommerce-product-gallery {
	max-width: 600px;
    position: relative;
    margin-bottom: 2rem;
    .woocommerce-product-gallery__trigger {
    	position: absolute;
    	top: 1rem;
    	right: 1rem;
    	z-index: 99;
    }
    a {
    	img {
	    	width: 100%;
	    	height: auto;
	    }
	}
	.flex-viewport {
		margin-bottom: 20px;
	}
	.flex-control-nav.flex-control-thumbs {
		display: flex;
	    list-style: none;
	    justify-content: space-between;
	    margin: 0;
	}
}

.ova-product-img {

	.featured-img {
		margin-bottom: 2px;
		overflow: hidden;

		img {
			width: 100%;
			height: auto;
		}
	}

	a {
		

		&:hover {
			img {
    			-webkit-transform: scale3d(1.05, 1.05, 1.05);
    			-moz-transform: scale3d(1.05, 1.05, 1.05);
    			-ms-transform: scale3d(1.05, 1.05, 1.05);
    			-o-transform: scale3d(1.05, 1.05, 1.05);
    			transform: scale3d(1.05, 1.05, 1.05);
			}
		}

		img {
			-webkit-transition: transform 0.5s ease-out;
			-moz-transition: transform 0.5s ease-out;
			-ms-transition: transform 0.5s ease-out;
			-o-transition: transform 0.5s ease-out;
			transition: transform 0.5s ease-out;
		}
	}

	.gallery {
		width: 100%;

		.product-gallery {
			.gallery-item {
				overflow: hidden;
			}
		}
	}

	.data-gallery {
		display: none;
	}
}