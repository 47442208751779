.ovabrw-search-ajax {
	.wrap-search-ajax {
		
		.modal-container {
			display: none;
			background-color: rgba( 0, 0, 0, .2 );
			align-items: center;
			justify-content: center;
			position: fixed;
			top: 0;
			left: 0;
			height: 100vh;
			width: 100vw;
			z-index: 10;

			.modal {
			    width: 900px;
	    		height: 500px;
	    		margin: 0 auto;
	    		position: relative;
	    		&.modal-booking-tab {
                    width: 630px;
                    @media screen and (max-width: 900px) {
		    			width: 530px;
		    		}

		    		@media screen and (max-width: 788px) {
		    			width: 500px;
		    		}

		    		@media screen and (max-width: 620px) {
		    			width: 400px;
		    		}

		    		@media screen and (max-width: 420px) {
		    			width: 320px;
		    		}

		    		@media screen and (max-width: 330px) {
		    			width: 300px;
		    		}
	    		}

	    		@media screen and (max-width: 900px) {
	    			width: 768px;
	    			height: 450px;
	    		}

	    		@media screen and (max-width: 788px) {
	    			width: 600px;
	    			height: 350px;
	    		}

	    		@media screen and (max-width: 620px) {
	    			width: 400px;
	    			height: 250px;
	    		}

	    		@media screen and (max-width: 420px) {
	    			width: 320px;
	    			height: 200px;
	    		}

	    		@media screen and (max-width: 330px) {
	    			width: 300px;
	    			height: 180px;
	    		}

	    		.modal-close{
	    			display: flex;
				    align-items: center;
				    justify-content: center;
				    border-radius: 50%;
				    width: 20px;
				    height: 20px;
				    background-color: rgba(255, 255, 255);
				    position: absolute;
				    right: -20px;
				    top: -20px;
				    cursor: pointer;
				    -moz-transition: all 0.3s ease;
				    -webkit-transition: all 0.3s ease;
				    transition: all 0.3s ease;

	    			i {
					    font-size: 7px;
					    line-height: 1;
					    color: #000;
					    font-weight: 600;
					    -moz-transition: all 0.3s ease;
					    -webkit-transition: all 0.3s ease;
					    transition: all 0.3s ease;
		    		}

		    		&:hover {
						background-color: var(--primary);

						i {
							color: #FFF;
						}
					}
	    		}
	    		
	    		.modal-popup-close{
	    			display: flex;
				    align-items: center;
				    justify-content: center;
				    border-radius: 50%;
				    width: 20px;
				    height: 20px;
				    background-color: rgba(255, 255, 255);
				    position: absolute;
				    right: -20px;
				    top: 100px;
				    cursor: pointer;
				    -moz-transition: all 0.3s ease;
				    -webkit-transition: all 0.3s ease;
				    transition: all 0.3s ease;

				    @media screen and (max-width: 767px) {
		    			top: 70px;
		    		}

	    			i {
					    font-size: 7px;
					    line-height: 1;
					    color: #000;
					    font-weight: 600;
					    -moz-transition: all 0.3s ease;
					    -webkit-transition: all 0.3s ease;
					    transition: all 0.3s ease;
		    		}

		    		&:hover {
						background-color: var(--primary);

						i {
							color: #FFF;
						}
					}
	    		}

	    		.modal-video {
	    			width: 100%;
	    			height: 100%;
	    		}

	    		.room-tabs {
	    			position: absolute;
				    top: 120px;
				    height: auto;
				    max-height: 80vh;
				    overflow: auto;
				    padding-bottom: 10px;  
	    			@media screen and (max-width: 767px) {
		    			top: 90px;
		    		}
                    .forms-booking-tab {
                        height: 100%;
                    }
	    		}
			}
		}
	}

	.wrap-load-more {
		width: 100%;
		display: none;
		position: relative;

		.loader {
			position: absolute;
			bottom: -90px;
			left: 50%;
			transform: translate(-50%);
			stroke-linecap: round;
			circle {
				fill: none;
				stroke-width: 1.5;
				animation-duration: 1.5s;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
				transform-origin: 25px 25px;
				will-change: transform;
				stroke: var(--primary);

				&:nth-of-type(1) {
					stroke-dasharray: 40px;
					animation-name: preloader_1;
				}

				&:nth-of-type(2) {
					stroke-dasharray: 80px;
					animation-name: preloader_2;
				}
			}

			@keyframes preloader_1 {
				100% {
					transform: rotate(360deg);
				}
			}
			@keyframes preloader_2 {
				100% {
					transform: rotate(-360deg);
				}
			}
		}
	}

	.search-ajax-result {
         margin-top: 110px;
         @media (max-width: 1024px) {
         	margin-top: 80px;
         }
		.ovabrw-pagination-ajax {
			margin-top: 60px;
			ul {
			    padding: 0px;
			    margin: 0px;
			    list-style-type: none;
			    display: flex;
			    flex-wrap: wrap;
			    justify-content: center;

			    li {
			    	cursor: pointer; 
		    	    margin: 0 2px;
		    	    span {
		    	    	padding: 0px 10px;
						min-width: 54px;
						font-size: 1em;
						color: var(--heading);
					    height: 40px;
					    display: inline-flex;
					    justify-content: center;
					    align-items: center;
					    -webkit-transition: all .3s ease;
					    -o-transition: all .3s ease;
					    transition: all .3s ease;

					    i {
					    	&:before {
					    		font-size: 1.2em;
					    	}
					    }

					    &:hover {
					    	background-color: var(--primary);
		    	    		color: #fff;
					    }
		    	    }
		    	    .current {
		    	    	background-color: var(--primary);
		    	    	color: #fff;
		    	    }
			    }
			}
		}
	}
}