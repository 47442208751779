.ovabrw-search {

	.ovabrw-search-form {
		display: flex;
		justify-content: center;
		@media (max-width: 1024px) {
			flex-direction: column;
		}

		.ovabrw-s-field {
			display: inline-flex;
			background: #fff;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

			@media (max-width: 767px) {
				display: grid;
				grid-template-columns: 1fr 1fr;
				padding: 30px 0 30px 0;
			}


			.search-field {
				position: relative;
				width: 100%;
				padding: 30px 30px 30px 50px;
				border-right: 1px solid var(--border);
				@media (max-width: 1024px) {
					padding-right: 15px;		
				}
				@media (max-width: 767px) {
					border-right: none;
					padding: 15px 30px 15px 50px;			
				}

				&:last-child {
					border-right: none;
				}

				.ovabrw-label {
					text-align: left;
					line-height: 1;
				    margin-bottom: 4px;
				    i {
						position: absolute;
						top: 50%;
						transform: translateY(-50%); 
						left: 20px;
						font-size: 18px;
						color: var(--text);
					}

					.label {
						font-weight: 500;
						font-size: 16px;
						line-height: 1.4px;
						color: var(--heading);
					}
				}

				.ovabrw-input {
					display: flex;
					align-items: center;
					position: relative;

					input[type="text"] {
						border: none;
						padding: 0;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: var(--light);
						width: 100%;
						max-width: 110px;
						cursor: pointer;
						background-color: transparent;
						&::placeholder {
							color: var(--light);
							opacity: 1;
						}
						@media (max-width: 1024px) {
							max-width: 100%;				
						}
					}
				}

				.ovabrw-guestspicker-content {
					display: none;
					opacity: 0;
					visibility: hidden;
					position: absolute;
					min-width: 245px;
					margin-top: 5px;
					top: 110px;
					left: 0;
					z-index: 2;
					padding: 16px;
					background-color: #fff;
					border: 1px solid var(--border);
					box-sizing: border-box;
					box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05);
					border-radius: 5px;
					@media (max-width: 767px) {
						top: 90px;
						left: 50px;
					}

					&:before {
						top: -7px;
						left: 50%;
						margin-left: -3.5px;
					    border-right: 7px solid transparent;
					    border-left: 7px solid transparent;
					    border-bottom: 7px solid #efefef;
					    position: absolute;
					    display: inline-block;
					    content: '';
					}

					.guests-buttons {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.description {
							label {
                                font-weight: 500;
								font-size: 16px;
								line-height: 19px;
								color: #000000;
							}
						}

						&:first-child {
							padding-bottom: 16px;
							border-bottom: 1px solid #EEEEEE;
						}

						&:last-child {
							padding-top: 10px;
						}
					}
				}

				.ovabrw-guestspicker {
					cursor: pointer;

					.guestspicker {
						text-align: left;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: var(--light);

						.gueststotal {
							display: inline-block;
							width: 11px;
							margin-right: 3px;
						}
					}
				}

				&.guestspicker-control {
					&.active {
						.ovabrw-guestspicker-content {
							display: block;
							opacity: 1;
							visibility: visible;
						}
					}
				}

				.guests-button {
					display: flex;
					align-items: center;

					input {
						width: 30px;
						height: 30px;
						padding: 0;
						background-color: transparent; 
						text-align: center;
						border: none;
						pointer-events: none;
						font-weight: 500;
						font-size: 16px;
						line-height: 19px;
						color: var(--heading);
					}

					.guests-icon {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 25px;
						height: 25px;
					    text-align: center;
					    border: none;
					    border-radius: 4px;
					    background-color: #F9F9F9;
					    color: var(--heading);
					    -webkit-transition: all .3s ease;
						-moz-transition: all .3s ease;
						-ms-transition: all .3s ease;
						transition: all .3s ease;

					    i {
					    	font-size: 10px;
					    }

					    &:hover {
					    	color: #FFF;
					    	background-color: var(--primary);
					    }
					}
				}

			}

		}

		.ovabrw-search-btn {
			button.ovabrw-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 0;
				height: 100%;
				width: 100%;
				min-width: 155px;
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
				background-color: var(--primary);
				font-weight: 600;
				font-size: 16px;
				line-height: 1.5;
				color: #fff;
				padding: 30px;
				border: 0;
				-webkit-transition: all .3s ease;
				-moz-transition: all .3s ease;
				-ms-transition: all .3s ease;
				transition: all .3s ease;

                @media (max-width: 1024px) {
                	padding: 25px;
                }

				i {
					margin: 3px 8px 0 0;
				}

				&:hover {
					background-color: var(--heading);
				}
			}
		}

	}
}

.woocommerce-products-header__title {
	margin-bottom: 40px;
}