.ovabrw-room-content2 {
	margin-bottom: 50px;
	@media (max-width: 1024px) {
		margin-bottom: 30px;
	}
	.wrapper-room {
		display: flex;
		display: -webkit-flex;
		background-color: #fff;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
		width: 100%;
		overflow: hidden;
		@media (max-width: 1024px) {
			flex-direction: column;
		}

		.room-img {
			position: relative;
			width: 50%;
			overflow: hidden;
			@media (max-width: 1024px) {
				width: 100%;
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}

			.room-video-gallery-wrapper {
                position: absolute;
                bottom: 30px;
                left: 30px;
                display: flex;
                align-items: center;

                .room-video-gallery {
                	-webkit-transition: all .3s ease;
					-moz-transition: all .3s ease;
					-ms-transition: all .3s ease;
					transition: all .3s ease;
                	&:hover {
                		-webkit-transition: all .3s ease;
						-moz-transition: all .3s ease;
						-ms-transition: all .3s ease;
						transition: all .3s ease;
                		background-color: var(--primary);
                		.text, i {
                			transition: all .3s ease;
                            color: #fff;
                		}
                	}
                	margin: 0 4px;
                	display: inline-flex;
                    align-items: center;
                    justify-content: center;
                	padding: 6px 8px;
                    background: rgba(255, 255, 255, 0.8);
                    cursor: pointer;

                    .text {
                    	transition: all .3s ease;
                    	font-size: 14px;
                    	color: var(--text);
                    	line-height: 1;
                    }

                    i {
                    	transition: all .3s ease;
                    	color: var(--heading);
                    	font-size: 16px;
                    	margin-right: 4px;
                    }
                }
			}
		}

		.room-container {
			padding: 40px;
			width: 50%;
			position: relative;
			@media (max-width: 1024px) {
				width: 100%;
				padding: 36px 20px;
			}

			.room-title {
				margin: 0; 
				padding-bottom: 25px;
				font-weight: 400;
				font-size: 30px;
				line-height: 1.2;
				
				a {
					color: var(--heading);
					-webkit-transition: all .3s ease;
					-moz-transition: all .3s ease;
					-ms-transition: all .3s ease;
					transition: all .3s ease;

					&:hover {
						color: var(--primary);
					}
				}
			}

			.room-info {
				list-style-type: none;
				margin: 0 0 15px 0;
				padding: 0;
				background: rgba(255, 255, 255, 0.16);
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				@media (max-width: 440px) {
					grid-template-columns: 1fr 1fr;
				}
			    li {
			    	display: flex;
			    	align-items: center;
			    	font-size: 18px;
			    	line-height: 1.2;
			    	color: var(--text);
                    margin: 0 18px 18px 0;
                    @media (max-width: 440px ) {
                    	font-size: 16px; 
                    }
                    i {
                    	color: var(--primary);
                    	font-size: 34px;
                    	line-height: 1;
                    	margin-right: 12px;
                    }
                    &.square {
                    	span {
                    		text-transform: lowercase; ;
                    	}
                    }
			    }
			}

			.room-short-description {
				margin: 0 0 40px 0;
				max-width: 460px;
				color: var(--heading);
				@media (max-width: 1024px) {
					max-width: 100%;
				}
			}

			.room-container-footer {
				display: flex;
				align-items: center;
				justify-content: space-between; 
				flex-wrap: wrap;
				gap: 20px;
				border-top: 1px solid var(--border);
				padding-top: 40px;
				.room-price {
					.text-before {
						display: block;
						margin-bottom: 3px;
					}
					.price-amount {
						font-size: 30px;
						font-weight: 500;
						line-height: 1;
						color: var(--primary);
					}
					.text-after {
						text-transform: capitalize;
						font-weight: 500;
					}
				}

				.room-button-wrapper {
					display: flex;
					flex-wrap: wrap;
					row-gap: 20px;
					.room-button-view-deals {
						padding-right: 15px;
						a {
							display: inline-flex;
							justify-content: center;
							align-items: center;
							background-color: #fff;
			                border: 2px solid var(--primary);
			                padding: 16px 22px;
			                font-size: 16px;
			                line-height: 1.2;
			                font-weight: 600;
			                color: var(--primary);
			                transition: all .3s ease;
			                -webkit-transition: all .3s ease;
			                -moz-transition: all .3s ease;
			                -ms-transition: all .3s ease;
                            i {
                             	margin-left: 8px; 
                             	line-height: 1; 
                            }
                            @media (max-width: 440px ) {
		                    	padding: 15px; 
		                    }
			                &:hover {
			                	transition: all .3s ease;
			                	background-color: var(--primary);
			                	color: #fff;
			                }
						}
					}

					.room-button {
						cursor: pointer;
						transition: all .3s ease;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						color: #fff;
						background-color: var(--primary);
		                padding: 16px 22px;
		                font-size: 16px;
		                line-height: 1.2;
		                font-weight: 600;
		                border: 2px solid var(--primary);
		                i {
                            margin-left: 8px;
                            line-height: 1; 
                        }

                        @media (max-width: 440px ) {
	                    	padding: 15px; 
	                    }
               
		                &:hover {
		                	transition: all .3s ease;
		                	background-color: #fff;
		                	color: var(--primary);
		                }
					}
				}

			}
		}
	}
}

.fancybox-container {
    .fancybox-thumbs {
	    top: auto;
	    width: auto;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    height: 95px;
	    padding: 10px 10px 5px 10px;
	    box-sizing: border-box;
	    background: rgba(0, 0, 0, 0.3);
	}
	.fancybox-thumbs .fancybox-thumbs__list {
	    margin: 0 auto;
	}
}

.fancybox-show-thumbs {
	.fancybox-inner {
        right: 0 !important; 
        bottom: 95px;
	}
}