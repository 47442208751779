.ovabrw-room-content3 {
	margin-bottom: 70px;
	@media (max-width: 1024px) {
		margin-bottom: 40px;
	}

	&:nth-child(even) {
		.wrapper-room {
			.room-img {
                margin-left: auto;
                .room-video-gallery-wrapper {
                	left: unset;
                	right: 30px;
                	@media (max-width: 1024px) {
						right: unset;
						left: 30px;
					}
                }
                @media (max-width: 1024px) {
					margin-left: 0;
				}
			}
			.room-container {
				right:unset;
				left: 10%;
				@media (max-width: 1024px) {
					left: unset;
				}
			}
		}
	}

	.wrapper-room {
		position: relative;

		.room-img {
			position: relative;
			width: 58%;
			@media (max-width: 1024px) {
				width: 100%;
			}

			img {
				display: block;
				width: 100%;
				max-height: 460px;
				object-fit: cover;
				object-position: center;
			}

			.room-video-gallery-wrapper {
                position: absolute;
                bottom: 30px;
                left: 30px;
                display: flex;
                align-items: center;

                .room-video-gallery {
                	-webkit-transition: all .3s ease;
					-moz-transition: all .3s ease;
					-ms-transition: all .3s ease;
					transition: all .3s ease;
                	&:hover {
                		-webkit-transition: all .3s ease;
						-moz-transition: all .3s ease;
						-ms-transition: all .3s ease;
						transition: all .3s ease;
                		background-color: var(--primary);
                		.text, i {
                			transition: all .3s ease;
                            color: #fff;
                		}
                	}
                	margin: 0 4px;
                	display: inline-flex;
                    align-items: center;
                    justify-content: center;
                	padding: 6px 8px;
                    background: rgba(255, 255, 255, 0.8);
                    cursor: pointer;

                    .text {
                    	transition: all .3s ease;
                    	font-size: 14px;
                    	color: var(--text);
                    	line-height: 1;
                    }

                    i {
                    	transition: all .3s ease;
                    	color: var(--heading);
                    	font-size: 16px;
                    	margin-right: 4px;
                    }
                }
			}
		}

		.room-container {
			position: absolute;
			padding: 30px;
			top: 50%;
			right: 10%;
			transform: translateY(-50%);
			width: 46%;
			box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
			background-color: #fff;
			@media (max-width: 1024px) {
				width: 100%;
				position: static;
				transform: translateY(0);
				top: unset;
				right: unset;
			}

			.room-price {
				margin-bottom: 10px;
				.price-amount {
					font-size: 24px;
					font-weight: 500;
					line-height: 1;
					color: var(--primary);
				}
				.text-after {
					text-transform: capitalize;
					font-weight: 500;
				}
			}

			.room-title {
				margin: 0; 
				padding-bottom: 16px;
				font-weight: 400;
				font-size: 30px;
				line-height: 1.2;
				
				a {
					color: var(--heading);
					-webkit-transition: all .3s ease;
					-moz-transition: all .3s ease;
					-ms-transition: all .3s ease;
					transition: all .3s ease;

					&:hover {
						color: var(--primary);
					}
				}
			}

			.room-info {
				list-style-type: none;
				margin: 0 0 15px 0;
				padding: 0;
				background: rgba(255, 255, 255, 0.16);
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				@media (max-width: 440px) {
					grid-template-columns: 1fr 1fr;
				}
			    li {
			    	display: flex;
			    	align-items: center;
			    	font-size: 16px;
			    	line-height: 1.2;
			    	color: var(--heading);
                    margin: 0 10px 10px 0;
                    i {
                    	color: var(--primary);
                    	font-size: 20px;
                    	line-height: 1;
                    	margin-right: 10px;
                    }
                    &.square {
                    	span {
                    		text-transform: lowercase; ;
                    	}
                    }
			    }
			}

			.room-short-description {
				margin: 0 0 30px 0;
				max-width: 460px;
				color: var(--heading);
				@media (max-width: 1024px) {
					max-width: 100%;
				}
			}

			.room-container-footer {
				border-top: 1px solid var(--border);
				padding-top: 25px;

				.room-button-wrapper {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					justify-content: space-between;
					row-gap: 20px;
					.room-button-view-deals {
						padding-right: 15px;
						a {
							display: inline-flex;
							justify-content: center;
							align-items: center;
							background-color: #fff;
			                font-size: 14px;
			                line-height: 1.2;
			                font-weight: 600;
			                color: var(--heading);
			                transition: all .3s ease;
			                -webkit-transition: all .3s ease;
			                -moz-transition: all .3s ease;
			                -ms-transition: all .3s ease;
                            i {
                             	margin-left: 6px; 
                             	line-height: 1; 
                            } 
			                &:hover {
			                	transition: all .3s ease;
			                	color: var(--primary);
			                }
						}
					}

					.room-button {
						cursor: pointer;
						transition: all .3s ease;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						color: #fff;
						text-transform: uppercase;
						background-color: var(--primary);
		                padding: 16px 28px;
		                font-size: 14px;
		                line-height: 1.2;
		                font-weight: 600;
		                border: 2px solid var(--primary);
		                border-radius: 5px;
               
		                &:hover {
		                	transition: all .3s ease;
		                	background-color: #fff;
		                	color: var(--primary);
		                }
					}	
				}

			}
		}
	}
}