.elementor-ralated-slide {
	.elementor-ralated {
		.owl-dots{
			text-align: center;
			margin-top: 80px;
			@media( max-width: 1200px ){
				margin-top: 40px;
			}
			.owl-dot{
				outline: none;
				span{
					width: 15px;
					height: 5px;
					margin: 5px;
					background: #e5e5e5;
					display: block;
					-webkit-backface-visibility: visible;
					transition: opacity .2s ease;
					border-radius: 2px;
				}
				&.active{
					span{
						width: 30px;
						background: var(--primary);
						opacity: 1;
					}
				}
			}
		}
	}
}
