/* Single Features */
ul.ovabrw_woo_features{
	list-style: none;
	padding: 0;
	margin: 0;
	display: grid;
	grid-gap: 30px; 
	&.four_column{
	  	grid-template-columns: 1fr 1fr 1fr 1fr;	
	  	@media( max-width: 1200px ){
			grid-template-columns: 1fr 1fr 1fr;
		}
		@media( max-width: 980px ){
			grid-template-columns: 1fr 1fr;
		}
		@media( max-width: 320px ){
			grid-template-columns: auto;	
		}
	}

	&.three_column{
	  	grid-template-columns: 1fr 1fr 1fr;	
	  	@media( max-width: 1200px ){
			grid-template-columns: 1fr 1fr;
		}
		@media( max-width: 320px ){
			grid-template-columns: auto;	
		}
	}

	&.two_column{
	  	grid-template-columns: 1fr 1fr;	
	  	@media( max-width: 320px ){
			grid-template-columns: auto;	
		}
	}

	li{
		label{
			display: block;
			font-weight: 500;
			margin-top: 5px;
			color: var(--text);
		}
		i {
			font-size: 36px;
			color: var(--primary);
		}
	}
}
/* /Single Features */

ul.ovabrw_cus_taxonomy{
	list-style: none;
	margin: 15px 0px;
	padding: 0;
	border-bottom: 1px solid #f1f1f1;
	padding-bottom: 15px;
	li{
		label{
			font-weight: 600;
			min-width: 200px;
			display: inline-block;
		}
		span {
			font-weight: 400;
		}
	}
}

/* Loop Features */
.ovabrw-features {
	display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 15px 0 15px 0;
    padding: 0 8px;
	.feature-item {
		width: 50%;
		display: flex;
    	justify-content: end;
		padding-bottom: 8px;
	    color: #343434;
	    opacity: .8;
	    font-weight: 500;

		i {
			font-size: 18px;
		    margin-right: 8px;
		    font-weight: 500;
		    opacity: .6;
		    color: #000;
		}
		
	}
}
/* /Loop Features */

/*Loop Custom taxonomy*/
.product_listing_custom_tax{
	list-style: none;
	margin: 10px 0px;
	padding: 0;
	li{
		display: inline-block;
		margin: 0 10px;
	}
}
/* /Loop Custom taxonomy*/


/* Single Attribute */
.ovabrw_product_attr {
	margin: 0px 0 15px 0;
}



/* Single Table Price */
.product_table_price{
	margin-bottom: 30px;
	@media(max-width: 992.98px){
		margin-bottom: 30px;
	}
	.ovacrs_price_rent{
		width: 100%;
		display: block;
		margin-bottom: 30px;
		clear: both;
		h3 {
			font-size: 35px;
			font-weight: 400;
		}
	
		.ovabrw_collapse_content{
			padding-top: 20px;
			.price{
				margin-bottom: 20px;
				label{
					display: inline-block;
					font-weight: 400;
					font-size: 16px;
					padding-right: 15px;
					color: var(--heading);
				}
				.amount{
					font-size: 24px;
					color: var(--text);
					font-weight: 400;
				}
			}
			.price_table{

				label{
					display: inline-block;
					font-weight: 400;
					margin: 20px 0;
					font-size: 22px;
					line-height: 1.5;
					color: var(--heading);
					.woocommerce-Price-amount{
						color: var(--heading);
					}
				}
				table:not( .has-background ) th {
					background: transparent;
				}
				table{
					border: none;
					tr,td,th{
						border: none;	
					}

					thead{
						background-color: var(--secondary);
						th{
							color: var(--heading);	
						}
					}
					tr{
						&.odd{
							background-color: #f8f8f8;
						}
						&.eve{
							background-color: #fff;	
						}
						td{
							font-size: 16px;
							color: var(--heading);
							a{
								color: var(--heading);	
							}
						}
					}
				}
			}
		}

		.ovacrs_rt_discount{
			.time_discount{
				margin-bottom: 15px;
				.time{
					color: #343434;
				}
			}

		}

		 @media( max-width: 767.98px){

		 	/* table price */
		 	.ovabrw_collapse_content .price_table{
		 		label{
		 			color: #333333;
		 		}
		 		table{
		 			thead{
		 				display: none;
		 			}
		 			tr td{
		 				display: block;
		 				text-align: right;
		 				padding: 5px 10px!important;

		 				&.bold{
		 					color: #636363!important;	
		 					font-weight: 500!important;
		 					display: none;
		 				}
		 				&:before{
		 					content: attr(data-title) ": ";
		 					font-weight: 600;
		 					float: left;
		 					color: #343434;
		 				}
		 			}
		 		}

		 	}
		 	.ovacrs_rt_discount{
		 		.time_discount{
		 			text-align: left;	
		 		}
		 		.close_discount{
		 			text-align: left;
		 		}
		 	}

		}

	}
}
/* /Single Table Price */

/* Single Unavaiable time */
.ovacrs_single_untime{
	margin: 15px 0px 30px 0px;
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
		li{
			margin-bottom: 10px;
		}
	}
}
/* /Single Unavaiable time */


/* Single Discount Popup */
.ovacrs_rt_discount{
	z-index: 11;
	&.popup {
		width:100%;
		height:100%;
		display:none;
		position:fixed;
		top:0px;
		left:0px;
		background:rgba(0,0,0,0.75);
		z-index: 9999;
	}

	/* Inner */
	.popup-inner {
		max-width:700px;
		width:90%;
		padding:40px;
		position:absolute;
		top:50%;
		left:50%;
		-webkit-transform:translate(-50%, -50%);
		transform:translate(-50%, -50%);
		box-shadow:0px 2px 6px rgba(0,0,0,1);
		border-radius:3px;
		background:#fff;
	}


	/* Close Button */
	.popup-close {
		width:28px;
		height:28px;
		display:inline-block;
		position:absolute;
		top:0px;
		right:0px;
		transition:ease 0.25s all;
		-webkit-transform:translate(50%, -50%);
		transform:translate(50%, -50%);
		border-radius:1000px;
		background:rgba(0,0,0,0.8);
		font-size:20px;
		text-align:center;
		line-height:100%;
		color:#fff !important; 
	}
	.popup-close:hover {
		-webkit-transform:translate(50%, -50%) rotate(180deg);
		transform:translate(50%, -50%) rotate(180deg);
		background:rgba(0,0,0,1);
		text-decoration:none;
	}
}	
/* /Single Discount Popup */


/* Single Calendar */
.wrap_calendar{
	height: auto;
	max-width: 660px; 
	background-color: #fff;
	border: 1.5px solid #CACACA;
	border-radius: 12px;
    padding: 30px 40px;
    margin: 0;
    position: relative;
    width: 100%;
    clear: both;
    @media( max-width: 767px ){
    	height: auto;
    	padding: 10px 20px;
    }
    @media( max-width: 460px ){
    	padding: 10px; 
    }
}

// Calendar
ul.intruction_calendar{
	position: relative;
	list-style: none;
	margin: 0;
	padding: 40px 0 0 0;

	li{
		padding-right: 36px;
		display: inline-flex;
		align-items: center;
		span {
			font-weight: 500;
			font-size: 18px;
			color: var(--heading);
		}
		.pink{
			width: 36px; height: 36px; background-color: var(--primary); border: 1px solid #cacaca; display: none;
		}
		.white{
			width: 36px; height: 36px; background-color: var(--primary); border: 1px solid #cacaca; display: inline-block;
			margin-right: 13px;
		}
		.maybe{
			margin-right: 13px;
			width: 36px; 
			height: 36px; 
			background-color: #fff; 
			border: 1px solid #cacaca; 
			display: inline-block; 
			position: relative;
			
		}
		.yellow{
			margin-right: 13px;
			width: 36px; height: 36px;  
			border: 1px solid rgba(189, 24, 13, 0.12); 
			display: inline-block; 
		}
	}
}

.ovabrw__product_calendar{

	table tbody tr td a, table tbody tr th a {
		border: none;
	}
	
	/*Toolbar: Button next, prev, today, month, week, day, lint, Title; */
	.fc-header-toolbar{

	    flex-direction: row-reverse;
		align-items: center;

		.fc-toolbar-title {
    		@media( max-width: 600px ){
		    	font-size: 22px;
		    }
    	}

		.fc-right{
			float: left;
			clear: both;
			h2{
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		.fc-button-group{
			margin-left: 0;
			margin-right: 10px;
			display: inline-block;

			.fc-button{
				border: none;
			}
			.fc-today-button, .fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button, .fc-listWeek-button {
				display: none;
			}
			.fc-button {
				&:hover {
					opacity: 1;
					background-color: transparent;
				}
			}
		}

		.fc-button{
			margin: 0px;
			height: 2.5em;
			padding: 5px 10px;
			
			background-color: transparent;
			color: var(--heading);
			background-image: none;
			text-transform: capitalize;
			opacity: 0.5;
			
			&:focus{
				outline: none!important;
				box-shadow: none;
				background-image: none!important;
			}
			.fc-icon-left-single-arrow:after{
				content: "\34";
				font-family: "ElegantIcons";
				font-size: 20px;
			}
			.fc-icon-right-single-arrow:after{
				content: "\35";
				font-family: "ElegantIcons";
				font-size: 20px;
			}
			&.fc-today-button{
				text-transform: capitalize;
			}
			&.fc-state-active{
				color: var(--heading);
				opacity: 1;
				background-image: none!important;
				box-shadow: none;
			}

		}

		@media( max-width: 767.98px){
			
			.fc-button-group{
				margin-left: 0;
				display: inline-block;
			}
			.fc-right{
				margin-top: 0;
			}

		}
	}

	.fc-col-header {
		margin-bottom: 0;
	}

	.fc-col-header-cell-cushion{
		color: var(--heading);
		font-size: 18px;
		font-weight: 600;
	}
	
	.fc-day{
		text-align: center;
	}

	.fc-day-past{
		background-color: #fd4c5c;
		.fc-daygrid-day-top, 
		.fc-daygrid-day-top .fc-daygrid-day-number{
			color: #fff;
		}
		.fc-daygrid-day-bg{
			.woocommerce-Price-amount{
				color: #fff;
			}
		}
	}

	.fc-daygrid-event-dot, .fc-event-time {
		display: none;
		white-space: break-spaces;
	}

	.fc-daygrid-event {
		background-color: #c4c4c4;

		.fc-sticky {
			display: none;
		}
	}

	&.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
		margin: 0;
	}

	.fc-daygrid-day-top, 
	.fc-daygrid-day-top .fc-daygrid-day-number{
		color: var(--heading);
		font-weight: 500;
	}

	.fc-daygrid-day-number{
		width: 100%;
		text-align: center;
	}

	.fc-daygrid-day-bg{
		.woocommerce-Price-amount{
			color: var(--heading);
			z-index: 9999;
    		position: relative;
		}
	}

	.fc-daygrid-day.fc-day-today {
		background-color: var(--primary);
		.fc-daygrid-day-top .fc-daygrid-day-number{
			color: #fff;
		}
		.fc-daygrid-day-bg{
			.woocommerce-Price-amount{
				color: #fff;
			}
		}
	}

	.fc-bg-event{
		opacity: 1;
	}

	.fc-day-other .fc-daygrid-day-top{
		opacity: 1;
	}
		
	/*hide booked time	*/

	&.ova-hide-time-calendar .fc-daygrid-day-events{
		display: none;
		height: 0;
	}

	&.fc-theme-standard .fc-popover{
		background: #fff;
    	z-index: 9999!important;
	}

	@media( max-width: 991.98px){
		width: 100%;
	}

}
/* /Single Calendar */


/* Tab Booking Form && Request Booking Form */
.forms-booking-tab{
	border: 1px solid #e5e5e5;
	background-color: #fff;
	border-radius: 5px;
	&.ovabrw-not-tabs{
		.request_booking {
			display: block;
		}
		ul.tabs {
			li.item{
				color: var(--primary);
				border-bottom: 4px solid var(--primary);
			}
		}
	}
	ul.tabs {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		justify-content: space-between;

		li.item {
			font-size: 20px;
			font-weight: 600;
			color: var(--text);
			text-transform: uppercase;
			width: 100%;
			text-align: center;
			padding: 20px 0;
			display: block;
			cursor: pointer;
			border-bottom: 1px solid #cacaca;

			&.active {
				color: var(--primary);
				border-bottom: 4px solid var(--primary);
			}
		}
	}
     
    .romancy-booking {
    	padding: 50px 0 0;

		@media( max-width: 992.98px ){
			padding: 30px 0 0;
		}

		label{
			display: block;
			font-weight: 600;
			font-size: 18px;
			line-height: 1.2;
			margin-bottom: 16px;
			color: var(--heading);
		}

		.ovabrw-container {
           margin-bottom: 5px;
           padding: 0 9px;
			.rb_field{
				margin-bottom: 30px;
				label.error{
					color: red;
					margin-top: 5px;
				}
			}

			select, input{
				border: 1px solid #cacaca;
				border-radius: 8px;
				height: 52px;
				width: 100%;
				outline: none;
				font-size: 16px;
				font-weight: 400;
				color: var(--heading);
				caret-color: var(--primary);
				&:focus{
					outline: none;
					box-shadow: none;
				}
				&::placeholder {
					font-size: 16px;
					line-height: 1.2;
					font-weight: 400;
					color: var(--heading);
					opacity: 0.4;
				}

			}
		}
     
		button.submit{
	 		text-transform: uppercase;
	 		color: #fff;
	 		background-color: var(--primary);
	 		padding: 18px 38px;
	 		border: none;
	 		border-radius: 8px;
	 		font-weight: 600;

	 		&:focus{
	 			outline: none;
	 		}
	 		&:hover{
	 			background-color: #25282e;
	 			cursor: pointer;
	 		}
	 	}

	 	// extra service 
		.ovabrw_extra_service {
			width: 100%;
			clear: both;
			margin: 0 15px;
			label {
				text-transform: uppercase;
				margin-bottom: 36px;  
			}
			.ovabrw_resource{
				.item{
					display: flex;
					justify-content: space-between; 
					margin-bottom: 16px;
					&:not(:last-child) {
						border-bottom: 1px solid #cacaca;
					}
					.left{
						display: inline-flex;
						label{
							display: inline-block;
							text-transform: capitalize;
							font-size: 16px;
							font-weight: 500;
							margin-bottom: 16px;
						}
						input{
							text-align: center;
							border-radius: 4px;
							background-color: #fff;
							border: 1px solid #cacaca;
							width: 24px;
							height: 24px;
							margin-right: 10px;
							appearance: none;
							&:hover {
								border-color: var(--primary);
							}
							&:checked {
								background-color: var(--primary); 
							}
							&:checked:after {
								position: relative;
								bottom: 2px;
		                   		font-family: "Font Awesome 5 Free" !important;
							    font-size: 13px;
							    font-weight: 600;
							    content: "";
							    color: white;
							}
						}
					}
					.right {
						color: var(--heading);
						font-weight: 500;
					}
				}
			}
		}


    }
	// Single Booking Form
	.ovabrw_booking_form{
		.wrap_fields{
			.ovabrw_datetime_wrapper {
				width: 100%; 
				display: inline-block;
				position: relative;
				&:before {
					position: absolute;
					left: 16px;
				    top: 50%;
				    font-size: 20px;
				    color: var(--light);
				    transform: translateY(-50%);
					content: "\f145";
					font-family: ovaicon !important;
				    font-style: normal;
				    font-variant: normal;
				    text-transform: none;
				    font-weight: normal;
				    line-height: 1;
				    -webkit-font-smoothing: antialiased;
				}
				input {
					padding: 15px 15px 15px 56px;
				}
			}
		}
	}

	// Single Request Booking
	.request_booking{
		display: none;

	 	.ovabrw_service_wrap{
	 		margin: 18px 9px 40px 9px;
			label {
				margin-left: 15px;
				text-transform: uppercase;
			}
 			.ovabrw_service{
 				margin: 0 15px;
 				display: grid;
 				grid-template-columns: 1fr 1fr;
 				grid-gap: 30px;
 				@media (max-width: 600px) {
 					grid-template-columns: auto;
 				}
 				select {
 					color: var(--heading); 
 				}
			}
		}

		button.submit {
			margin: 30px 24px;
		}

	 	.extra{
	 		margin: 30px 24px 0 24px;

	 		textarea {
	 			width: 100%;
	 			max-width: 100%;
	 			min-width: 100%;
	 			height: 100px;
	 			border: 1px solid #cacaca;
	 			background-color: #fff;
	 			border-radius: 4px;
	 			padding-left: 16px;
	 			padding-top: 5px;
	 			&:focus{
	 				outline: none;
	 			}
	 		}
	 	}

		.ovabrw_extra_service {
			width: auto;
			margin: 0 24px;
		}

	}

}
/* /Tab Booking Form && Request Booking Form */

/* Search Shortcode */
.ovabrw_wd_search{
 	
 	form{

 		.wrap_content{
 			display: inline-block;
 			width: 100%;
 			.s_field{
 				display: inline-block;
 				&.five-column {
 					width: 20%;
	 				@media( max-width: 991px ){
	 					width: 25%;
	 				}
	 				@media( max-width: 767px ){
	 					width: 50%;
	 				}
 				}
 				&.four-column {
 					width: 25%;
	 				@media( max-width: 991px ){
	 					width: 25%;
	 				}
	 				@media( max-width: 767px ){
	 					width: 50%;
	 				}
 				}
 				&.three-column {
 					width: 33.33%;
	 				@media( max-width: 991px ){
	 					width: 33.33%;
	 				}
	 				@media( max-width: 767px ){
	 					width: 50%;
	 				}
 				}
 				&.two-column {
 					width: 50%;
	 				@media( max-width: 991px ){
	 					width: 50%;
	 				}
	 				@media( max-width: 767px ){
	 					width: 100%;
	 				}
 				}

 				&.one-column {
 					width: 100%;
	 				@media( max-width: 991px ){
	 					width: 100%;
	 				}
	 				@media( max-width: 767px ){
	 					width: 100%;
	 				}
 				}

 				&.ovabrw-value-attribute {
 					display: none ;
 					&.active {
 						display: inline-block;
 					}
 				}
 				.content{
 					margin: 0px 5px 15px 5px;	

 					.select2-container.select2-container--default{
 						height: 39px;
 						line-height: 40px;
 						&:focus{
 							outline: none;
 						}
 						.selection{
 							&:focus{
 								outline: none;
 							}		
 						}
 					}
 				}
 			}
 			label{
 				display: block;
 				font-weight: 600;
 			}	
 			input, select{
 				border-radius: 4px;
 				border: 1px solid #dbdbdb;
 				height: 40px;
 				padding-left: 10px;
 				padding-right: 10px;
 				width: 100%;
 				background-color: #fff;
 				color: #343434;
 				&::-webkit-input-placeholder {
 					color: #343434;

 				}
 				&::-moz-placeholder {
 					color: #343434;

 				}
 				&:-ms-input-placeholder {
 					color: #343434;

 				}
 				&:-moz-placeholder {
 					color: #343434;

 				}

 				&:focus{
 					outline: none;
 				}
 			}


 		}
 		.s_submit{
 			clear: both;
 			margin-top: 20px;
 			text-align: center;
 			width: 100%;
 			button.submit{
 				padding: 15px 50px;
 				background-color: #333333;
 				border: 1px solid  #333333;
 				border-radius: 4px;
 				color: #fff;
 				text-transform: uppercase;
 				font-weight: 500;

 				&:hover{
 					background-color: #25282e;
 					border-color: #25282e;
 				}
 			}
 			.wrap_btn{
 				padding-left: 30px;
 				padding-right: 30px;
 				background-color: #fff;
 				position: relative;
 				
 				.wrap_btn_2{
 					position: relative;
 					background-color: #fff;
 					padding: 0px 30px;
 					z-index: 2;

 				}

 			}
 		}
 	}

 	.error{
 		color: red;
 		margin: 0px;
 		
 	}

 	@media( max-width: 991.98px ){
 	
	 	form .s_submit .wrap_btn:after{
	 		left: 0;
	 		right: 0;
	 	}
	}

}
/* /Search Shortcode */


/* Single  Deposit */
.ovabrw-deposit {
	margin: 0 15px;
	clear: both;
	width: 100%;
	display: block;
	.title-deposite {
		font-size: 16px;
		margin-bottom: 8px;
		color: #000;
	}
	.ovabrw-type-deposit {
		display: flex;
		label {
			color: #FFF;
			padding: 10px;
			font-size: 1em;
			font-weight: 500;
		}
	}
	input[type="radio"] {
		display: none;
		&:checked + label {
			background-color: var(--primary);
			opacity: 1;
			color: #fff;
		}
	}
	label {
		display: inline-block;
		background-color: var(--primary);
		opacity: 0.5;
		padding: 5px 22px;
		font-size: 14px;
		margin: 0px;
		color: #FFF;
	}

	.ovabrw-pay-deposit,
	.ovabrw-pay-full{
		cursor: pointer;
	}
}



/* Other */

.ovabrw-container{
	
	.ovabrw-row {
		
		.ovabrw_wd_search {
			padding: 0 15px;
		}
		.wrap-item {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			row-gap: 16px ;
			
			.rental_item  {
				.error_item {
					display: none;
					label {
						color: #f00;
						text-transform: inherit;
						margin-left: 0;
					}
				}

				.ovabrw-wrapper-guestspicker {
					position: relative;

					.ovabrw-guestspicker {
						padding: 12px 15px;
						background-color: #FFFFFF;
						border: 1px solid #cacaca;
					    border-radius: 8px;
					    height: 52px;
					    width: 100%;
					    outline: none;
					    font-size: 16px;
					    font-weight: 400;
					    color: var(--heading);
					    caret-color: var(--primary);
					    cursor: pointer;
					}

					.ovabrw-guestspicker-content {
						display: none;
						position: absolute;
						width: 240px;
						margin-top: 7px;
						left: 0;
						z-index: 1;
						padding: 16px;
						background-color: #FFFFFF;
						border: 1px solid #CACACA;
						box-sizing: border-box;
						box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05);
						border-radius: 8px;

						&:before {
							top: -7px;
							left: 50%;
							margin-left: -3.5px;
						    border-right: 7px solid transparent;
						    border-left: 7px solid transparent;
						    border-bottom: 7px solid #efefef;
						    position: absolute;
						    display: inline-block;
						    content: '';
						}

						.guests-buttons {
							display: flex;
							align-items: center;
							justify-content: space-between;

							.description {
								label {
	                                font-weight: 500;
									font-size: 16px;
									line-height: 19px;
									color: #000000;
									margin: 0;
								}
							}

							.guests-button {
								display: flex;
								align-items: center;

								input {
									width: 30px;
									height: 30px;
									padding: 0;
									text-align: center;
									border: none;
									pointer-events: none;
									font-weight: 500;
									font-size: 16px;
									line-height: 19px;
									color: var(--heading);
								}

								.guests-icon {
									display: flex;
									align-items: center;
									justify-content: center;
									width: 25px;
	    							height: 25px;
								    text-align: center;
								    border: none;
								    border-radius: 4px;
								    background-color: #F9F9F9;
								    cursor: pointer;
								    color: var(--heading);
								    -webkit-transition: all .3s ease;
									-moz-transition: all .3s ease;
									-ms-transition: all .3s ease;
									transition: all .3s ease;

								    i {
								    	font-size: 10px;
								    }

								    &:hover {
								    	color: #FFF;
								    	background-color: var(--primary);
								    }
								}
							}

							&:first-child {
								padding-bottom: 16px;
								border-bottom: 1px solid #EEEEEE;
							}

							&:last-child {
								padding-top: 10px;
							}
						}
					}
				}
			}
			.ovabrw_service_wrap{
				margin: 8px 0 16px 0;
				width: 100%;
				label {
					margin-left: 15px;
					text-transform: uppercase;
				}
				.ovabrw_service{
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					.ovabrw_service_select{
						margin-bottom: 10px;
					}
				}
			}
			&.two_column {
				.rental_item {
					flex: 0 0 50%;
					padding: 0 15px;
					@media (max-width: 767px) {
						flex: 0 0 100%;
					}
				}
				.ovabrw_service{
					.ovabrw_service_select{
						flex: 0 0 50%;
						padding: 0 15px;
						@media (max-width: 767px) {
							flex: 0 0 100%;
						}
					}
				}
			}
			&.three_column {
				.rental_item {
					padding: 0 10px;
					flex: 0 0 33.33%;
					@media (max-width: 767px) {
						flex: 0 0 100%;
					}
				}
				.ovabrw_service{
					.ovabrw_service_select{
						flex: 0 0 33.33%;
						padding: 0 10px;
						@media (max-width: 767px) {
							flex: 0 0 100%;
						}
					}
				}

			}
			&.four_cloumn {
				.rental_item {
					padding: 0 5px;
					flex: 0 0 25%;
					input {
						display: block;
						height: 40px;
						width: 100%;
					}
					@media (max-width: 991px) {
						flex: 0 0 50%;
					}
					@media (max-width: 767px) {
						flex: 0 0 100%;
					}
				}
				.ovabrw_service{
					.ovabrw_service_select{
						flex: 0 0 25%;
						padding: 0 5px;
						@media (max-width: 991px) {
							flex: 0 0 50%;
						}
						@media (max-width: 767px) {
							flex: 0 0 100%;
						}
					}
				}
			}
		}
		.ovabrw-order-result {
			padding: 0 15px;
			&:after {
				content: "";
				clear: both;
				display: block;
			}
		}
	}
	
}

.ovabrw-teplate-default {
	.ovabrw-container {
		.ovabrw-row {
			.wrap-item {
				.rental_item {
					input {
						width: 100%;
					}
				}
			}
		}
	}
}
/* ---Other */

.ui-autocomplete {
	padding: 0px;
	margin: 0px;
	list-style-type: none;
	width: 300px;
	background: #fff;
	z-index: 9;
	padding: 10px;
	border: 1px solid #e3e3e3;
	font-size: 14px;
	line-height: 1.2;
	.ui-menu-item {
		cursor: pointer;
		padding: 10px 5px;
		&:not(:last-child) {
			border-bottom: 1px solid #e3e3e3;
		}
		&:hover {
			background: #333333;
			color: #fff;
		}
	}
}


.rental_item{
	margin-bottom: 15px;
}

.ovabrw_woo_price{
	margin: 2px 0 2px 0;
	display: block;
	&:last-child {
		margin-bottom: 12px;
	}
}


/* /Other */

/* Ajax Total */
.booking-error {
	display: none;
	margin: 0 24px;
	color: #f00;
	font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
}

.ovabrw-book-now {
	position: relative;
	display: flex;
	flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 22px 23px 24px;
    margin-top: 32px;
    background-color: var(--secondary);
    border-radius: 0px 0px 5px 5px;
    @media (max-width: 530px) {
        flex-direction: row ;
        align-items: flex-start;
    }
    @media (max-width: 400px) {
    	flex-direction:  column;
    }
    .ajax_show_total {
         @media (max-width: 530px) {
	        margin-top: 0px;
	    }
	    @media (max-width: 400px) {
	    	margin-top: 40px;
	    }
    }
}

.ajax_show_total{
	display: flex;
	.show_ajax_content {
		opacity: 0;

		&.active{
			opacity: 1;
			transition-duration: 0.3s;
			color: blue;
			font-size: 1.2em;

			.show_total {
				font-weight: 600;
				font-size: 30px;
				color: #063C43;
			}
		}

		.ovabrw-show-total, .ovabrw-show-rooms {
			font-weight: 500;
			font-size: 16px;
			color: #808080;

			.show_availables_vehicle {
				color: #000;
			}
		}
	}
}

.ajax_loading {
	opacity: 0;	
	display: inline-block;
	width: 60px;
	height: 60px;
	position: absolute;
	right: 35%;

	&.active{
		opacity: 1;
	}

	@media screen and (max-width: 400px) {
		right: 10%;
	}
}

.ajax_loading:after {
  	content: " ";
  	display: block;
  	width: 60px;
  	height: 60px;
  	margin: 0px;
  	border-radius: 50%;
  	border: 3px solid var(--primary);
  	border-color: var(--primary) transparent var(--primary) transparent;
  	animation: ajax_loading 1.2s linear infinite;
}

@keyframes ajax_loading {
  	0% {
   	 	transform: rotate(0deg);
  	}
  	100% {
    	transform: rotate(360deg);
  	}
}


.ovabrw-price +.price{
	display: none;
}

// CSS extra item
.ovabrw_extra_item {
	text-align: left;
	display: block;
	dt {
		display: block;
	}
	dd {
		display: block;
	}
}

// CSS tax in cart deposit
.ova_tax_label {
	display: block;
	font-weight: 400;
}