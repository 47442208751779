/* Product infomation */
.ovabrw_product_info{
	.room-info {
		list-style: none;
		margin: 0;
		padding: 0;
		display: grid;
		grid-gap: 35px;

		&.four_column{
		  	grid-template-columns: 1fr 1fr 1fr 1fr;	
		  	@media( max-width: 1200px ){
				grid-template-columns: 1fr 1fr 1fr;
			}
			@media( max-width: 980px ){
				grid-template-columns: 1fr 1fr;
			}
			@media( max-width: 360px ){
				grid-template-columns: auto;	
			}
		}

		&.three_column{
		  	grid-template-columns: 1fr 1fr 1fr;	
		  	@media( max-width: 1200px ){
				grid-template-columns: 1fr 1fr;
			}
			@media( max-width: 360px ){
				grid-template-columns: auto;	
			}
		}

		&.two_column{
		  	grid-template-columns: 1fr 1fr;	
		  	@media( max-width: 360px ){
				grid-template-columns: auto;	
			}
		}

		li{
			display: flex;
			align-items: center;
			transition: all .3s ease;
			color: var(--primary);
			span{
				font-size: 20px;
				color: var(--text);
			}
			i {
				font-size: 34px;
				margin-right: 12px; 
			}
			&:hover {
				transition: all .3s ease;
			}
		}
	}
}