.ovabrw-search2 {

	.ovabrw-search-form {
		display: flex;
		justify-content: center;
		@media (max-width: 1024px) {
			flex-direction: column;
		}

		.ovabrw-s-field {
			display: inline-flex;
            justify-content: center;
            align-items: center;
            row-gap: 30px;
			background: rgba(9, 9, 9, 0.31);
			padding: 35px;
			padding-left: 0;
			@media (max-width: 1024px) {
				padding-right: 0;
			}
			@media (max-width: 767px) {
				display: grid;
				grid-template-columns: 1fr 1fr;
			}

			.search-field {
				position: relative;
				width: 100%;
				padding: 0 40px;
				border-right: 1px solid var(--light);			
				@media (max-width: 767px) {
					border-right: none;
					padding: 0px 20px;
				}

				&:last-child{
					border-right: none;
				}

				.ovabrw-label {
					display: flex;
				    align-items: center;
				    justify-content: flex-start;
				    margin-bottom: 8px;

					i {
						font-size: 20px;
						color: #fff;
						margin-right: 8px;
					}
					.label {
						font-weight: 500;
						font-size: 20px;
						line-height: 22px;
						color: #fff;
					}
				}

				.ovabrw-input {
					display: flex;
					align-items: center;
					position: relative;

					input[type="text"] {
						border: none;
						padding: 0;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						width: 100%;
						color: #fff;
						max-width: 135px;
						cursor: pointer;
						background-color: transparent;
						&::placeholder {
							color: #fff;
							opacity: 1;
						}
						@media (max-width: 1024px) {
							max-width: 100%;				
						}
					}
				}

				.ovabrw-guestspicker-content {
					display: none;
					opacity: 0;
					visibility: hidden;
					position: absolute;
					min-width: 245px;
					margin-top: 5px;
					top: 95px;
					left: 0;
					z-index: 2;
					padding: 16px;
					background-color: #fff;
					border: 1px solid var(--border);
					box-sizing: border-box;
					box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05);
					border-radius: 5px;
					@media (max-width: 767px) {
						top: 80px;
						left: 40px;
					}

					&:before {
						top: -7px;
						left: 50%;
						margin-left: -3.5px;
					    border-right: 7px solid transparent;
					    border-left: 7px solid transparent;
					    border-bottom: 7px solid #efefef;
					    position: absolute;
					    display: inline-block;
					    content: '';
					}

					.guests-buttons {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.description {
							label {
                                font-weight: 500;
								font-size: 16px;
								line-height: 19px;
								color: #000000;
							}
						}

						.guests-button {
							display: flex;
							align-items: center;

							input {
								width: 30px;
								height: 30px;
								padding: 0;
								text-align: center;
								border: none;
								pointer-events: none;
								font-weight: 500;
								font-size: 16px;
								line-height: 19px;
								color: var(--heading);
							}

							.guests-icon {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 25px;
    							height: 25px;
							    text-align: center;
							    border: none;
							    border-radius: 4px;
							    background-color: #F9F9F9;
							    color: var(--heading);
							    -webkit-transition: all .3s ease;
								-moz-transition: all .3s ease;
								-ms-transition: all .3s ease;
								transition: all .3s ease;

							    i {
							    	font-size: 10px;
							    }

							    &:hover {
							    	color: #FFF;
							    	background-color: var(--primary);
							    }
							}
						}

						&:first-child {
							padding-bottom: 16px;
							border-bottom: 1px solid #EEEEEE;
						}

						&:last-child {
							padding-top: 10px;
						}
					}
				}

				.ovabrw-guestspicker {
					cursor: pointer;
					.guestspicker {
						text-align: left;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: #fff;

						i {
							position: relative;
					        bottom: -4px;
							font-size: 16px;
							margin-left: 5px;
						}
						.gueststotal {
							display: inline-block;
							width: 11px;
							margin-right: 3px;
						}
					}
				}

				&.guestspicker-control {
					&.active {
						.ovabrw-guestspicker-content {
							display: block;
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}

		}

		.ovabrw-search-btn {
			button.ovabrw-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 0;
				height: 100%;
				width: 100%;
				min-width: 200px;
				background-color: var(--primary);
				font-weight: 500;
				font-size: 20px;
				line-height: 1.5;
				color: #fff;
				border: 1px solid transparent;
				padding: 30px;
				-webkit-transition: all .3s ease;
				-moz-transition: all .3s ease;
				-ms-transition: all .3s ease;
				transition: all .3s ease;

				i {
					margin: 3px 8px 0 0;
				}

				&:hover {
					border: 1px solid var(--primary);
					background-color: transparent;
					color: var(--primary);
				}
			}
		}

	}
}