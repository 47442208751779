.ovabrw-room-list {

	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;
	grid-row-gap: 60px;

	@media screen and (max-width: 1200px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
	}

	&.ovabrw-room-list-template2, &.ovabrw-room-list-template3 {
		display: block;
	}

}

.ovabrw-room-content1 {
	text-align: center;

	&:hover {
		.room-image {
            img {
             	transition: all .5s ease;
				transform: translateY(-10px);
            }
		}		
	}

	.room-image {
		display: inline-block;
		position: relative;
		margin-bottom: 40px;
		width: 100%;
		img {
			display: block;
			transition: all .5s ease;
			border-radius: 0;
		}
	}

	.room-info {
		padding: 0;
		list-style-type: none;
		display: inline-flex;
		align-items: center;
		li{
			position: relative;
			display: flex;
			padding: 0 12px;
			color: var(--text);
			i {
				font-size: 22px;
				margin-right: 10px;
			}
		}
	}

	.room-title {
		transition: all .3s ease;
		margin: 0;
		font-size: 33px;
		line-height: 1;
		font-weight: 400;
		color: var(--heading);
		&:hover {
			transition: all .3s ease;
			color: var(--primary);
		}
	}

	.room-price {
		position: absolute;		
		bottom: -20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		padding: 12px 20px;
		background-color: #fff;
		color: var(--text);
		line-height: 1;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
		
		.text-before {
			font-size: 20px;
			font-weight: 500;
		}
		.woocommerce-Price-amount, .text-after{
			font-size: 20px;
			font-weight: 600;
			color: var(--primary);
		}
	}

	.ova-room-book-now {
		display: block;
		margin-top: 10px;
		color: var(--text);
		&:hover {
			color: var(--primary);
		}
		.text-button {
			font-size: 19px;
			font-weight: 500;
			text-transform: uppercase;
		}
		i {
           font-size: 13px;
           line-height: 1;
		}
	}

}